import React from 'react';
import { Home } from 'lucide-react';

const ChatInput = ({ text, setText, handleSubmit, handleHome, isLoading }) => {
    return (
        <div className="chat-input-container">
            <div className="chat-input-wrapper">
                {/* 새로고침 */}
                <button 
                    onClick={handleHome} 
                    className="home-button"
                >
                    <Home className="w-5 h-5" />
                </button>
                {/* 채팅 입력 */}
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="메시지를 입력해주세요"
                    className="chat-input"
                    rows="1.5"
                    disabled={isLoading}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                />
                <button 
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="send-button"
                >전송
                </button>
            </div>
        </div>
    );
};

export default ChatInput;