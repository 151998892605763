import axios from "axios";
import ApiClient from "./ApiClient";

// rest api
export const ScenarioAPI = {
    keywordScenario: async (
        query,          // 유저가 물어본 내용
        metaData
    ) => {
        const response = await ApiClient.post(
            `/v1/scenario/keyword`,
            {
                user_query: query,
                meta_data: metaData
            }
        )
        return response;
    },

    showPackageScenario: async (
        query,
        packageData
    ) => {
        const response = await ApiClient.post(
            `/v1/scenario/show_package`,
            {
                user_query: query,
                meta_data: {
                    package_id_list: packageData
                }
            }
        )
        return response;
    }
}
