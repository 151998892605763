import { useRef, useEffect } from 'react';

// 화면 스크롤 하단 고정
export const useScroll = (msg) => {
    const messagesEndRef = useRef(null);
    
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
 
    useEffect(() => {
        scrollToBottom();
    }, [msg]);
 
    return messagesEndRef;
 };