import axios from 'axios';

// Set the base URL based on the environment
const ApiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
});


export default ApiClient;