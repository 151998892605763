import { v4 as uuidv4 } from 'uuid';

const sendUserMessage = async (userText) => {
    if(!userText.trim()) return;

    const userMessage = {
        id: uuidv4(),
        text: userText,
        type: 'user'
    }

    return userMessage;
};

const sendBotMessage = async (BotText) => {
    if(!BotText?.data?.data) return;

    const { data: {data, ok} } = BotText;

    const BotMessage ={
        id: data.message_id,
        text: ok === false ? "질문을 다시 입력해주세요" : data.bot_message,
        type: 'chatbot'
    };
    return BotMessage;
};

const sendScenarioMessage = async (ScenarioData) => {
    if(ScenarioData.ok === false) return;

    const {data: {data, ok, http_status_code}} = ScenarioData

    const BotMessage = {
        id: uuidv4(),
        text: data.bot_message.message,
        products: data.bot_message.message_meta,
        type: 'chatbot'
    };

    return BotMessage
}

const customerMessage = async (customerText, chatType) => {
    const Message = {
        id: uuidv4(),
        text: customerText, 
        type: chatType
    };
    
    return Message
}

export { 
    sendUserMessage, 
    sendBotMessage, 
    sendScenarioMessage,
    customerMessage,
};