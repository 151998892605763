import React, { useState } from 'react';

const ChatTable = ({ data }) => {

    // contents에서 url 분리
    const separateUrlsAndContent = (text) => {
        const check = /(https?:\/\/[^\s]+)/g;
        const urls = text.match(check) || [];
        const content = text.replace(check, '').trim();
        return { content, urls };
      };
    
    // 가격 format
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    
    return (
        <div className="chat-table-container">
            {data.map((item, index) => (
                <div key={index} className="chat-table-item">
                    <div className="product-info">
                        <div className="info-row">
                            <span className="label">이름</span>
                            <span className="value">{item.golf_resort.name}</span>
                        </div>
                        <div className="info-row">
                            <span className="label">날짜</span>
                            <span className="value">{item.dates}</span>
                        </div>
                        <div className="info-row">
                            <span className="label">내용</span>
                            <span className="value">{item.contents}</span>
                        </div>
                        <div className="info-row">
                            <span className="label">가격</span>
                            <span className="value">{formatPrice(item.greenFee)}원</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChatTable;