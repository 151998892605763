import React from 'react';

// 추 후, faq를 사용한다면 rest api를 통한 faqData 연결
const faqData = [
    { id: 1, question: "노캐디", answer: "노캐디에 대한 임시 답변입니다." },
    { id: 2, question: "9홀", answer: "9홀에 대한 임시 답변입니다." },
    { id: 3, question: "추천", answer: "추천에 대한 임시 답변입니다." },
];

const FAQ = ({ handleFaqClick, isLoading }) => {
    return (
        <div className='faq-container'>
            <div>
                {faqData.map((faq) => (
                    <button
                        key={faq.id}
                        onClick={() => handleFaqClick(faq)}
                        className='faq-tag'
                        disabled={isLoading}
                    >
                        {faq.question}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default FAQ;