import React from 'react';
import './App.css';

import Chat from './components/Chat';
import ChatInput from './components/ChatInput';
import FAQ from './components/FAQ';
import { useScroll } from './hooks/useScroll';
import { useChat } from './hooks/useChat';

const INITIAL_MESSAGE = '안녕하세요!';

const Chatbot = () => {
  
  const {
    messages, text, isLoading,
    onScenario, onFAQSubmit, setText
  } = useChat();

  const messagesEndRef = useScroll(messages);

  const handleHome = () => { window.location.href = '/'; };

  return (
    <div className="chat-wrapper">
      <div className="chat-container">

        <div className="chat-header">
          <div className="chat-logo">
            <span>logo</span>
          </div>
          <h1 className="chat-title">골프몬</h1>
        </div>
        
        <Chat
          messages={messages}
          INITIAL_MESSAGE={INITIAL_MESSAGE}
          messagesEndRef={messagesEndRef}
        />
        
        <FAQ
          handleFaqClick={onFAQSubmit}
          isLoading={isLoading}
        />

        <ChatInput
          text={text}
          setText={setText}
          handleSubmit={onScenario}
          handleHome={handleHome}
          isLoading={isLoading}
        />

      </div>
    </div>
  );
};

export default Chatbot;
