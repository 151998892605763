import React from 'react';
import ChatTable from './ChatTable';

const Chat = ({ messages, INITIAL_MESSAGE, messagesEndRef }) => {

    return(
        <div className="chat-messages">
            {/* 시작 멘트 */}
            <div className='message chatbot'>
                {INITIAL_MESSAGE}
            </div>
            {/* 채팅 */}
            {messages.map((msg) => (
                <div
                    key={msg.id}
                    className={`message ${msg.type}`}
                >{msg.text}
                {/* 상품 */}
                {msg.products && (
                    <ChatTable data={msg.products} />
                )}
                </div>    
            ))}
            <div ref={messagesEndRef}/>
        </div>
    );
};

export default Chat;