import { useState } from "react";
import { ScenarioAPI } from "../services/ScenarioAPI";
import { sendUserMessage, sendBotMessage, sendScenarioMessage, customerMessage } from "../Repository/Message";

export const useChat = () => {
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const [keyword, setKeyword] = useState('keyword');
    const [metaData, setmetaData] = useState(null);
    const [packageData, setPackageData] = useState([]);

    // Method    
    const onFAQSubmit = async (faq) => {
        if(!faq || isLoading) return;

        try{
            setIsLoading(true);

            // user
            const userMessage = await sendUserMessage(faq.question);
            setMessages(prev => [...prev, userMessage]);

            // 임시 FAQ response
        const faqResponse = {
            data: {
                    ok: true,
                    data: {
                        user_query: faq.question,
                        bot_message: faq.answer,
                        message_id: faq.id
                    }
                }
            };

            // bot message
            const botMessage = await sendBotMessage(faqResponse);
            setMessages(prev => [...prev, botMessage]);

        } catch (error) {
            console.error("FAQ error: ", error);
            alert('FAQ 불러오기에 실패했습니다.');
        } finally {
            setIsLoading(false);
        };
    }; 

    const onScenario = async (e) => {
        e.preventDefault()
        if (!text.trim() || isLoading) return;

        try {
            setIsLoading(true);
            
            // user 
            const userMessage = await sendUserMessage(text);
            setMessages(prev => [...prev, userMessage]);
            setText('');
            
            // chatbot 
            if (keyword === 'keyword') {
                const response = await ScenarioAPI.keywordScenario(text, metaData);
                
                const checkList = !!response?.data?.data?.next_scenario_meta_data?.package_id_list;
                if (checkList === false){
                    // chat message
                    const botMessage = await sendScenarioMessage(response);
                    setMessages(prev => [...prev, botMessage]);

                    // status 변경
                    const msgData = response.data.data;
                    setKeyword(msgData.next_scenario_code);
                    setmetaData(msgData.next_scenario_meta_data);
                }
                else{
                    // chat message
                    const botMessage = await sendScenarioMessage(response);
                    setMessages(prev => [...prev, botMessage]);

                    // status 변경
                    const msgData = response.data.data;
                    setKeyword(msgData.next_scenario_code);
                    setmetaData(msgData.next_scenario_meta_data);
                    setPackageData(msgData.next_scenario_meta_data.package_id_list);
                }
                
            } else if (keyword === 'show_package') {  
                const response = await ScenarioAPI.showPackageScenario(text, packageData);

                // chat message
                const Message = await sendScenarioMessage(response);
                
                // product 유효성 검사
                if (Message.products && Message.products.length > 0){
                    setMessages(prev => [...prev, Message]);
                } else {
                    const emptyProducts = await customerMessage(
                        '검색하신 상품이 존재하지 않습니다.',
                        'chatbot'
                    );
                    setMessages(prev => [...prev, emptyProducts]);
                }
                
                // 추가 안내 메시지
                const additionalMessage = await customerMessage(
                    '찾으시는 예약 상품이 더 있으신가요?',
                    'chatbot'
                );
                setMessages(prev => [...prev, additionalMessage]);

                // status 변경
                const msgData = response.data.data;
                setKeyword('keyword');
                setmetaData(null);
                setPackageData([]);
            }  
             
        } catch (error) {
            console.error('Chat Error:', error);
            alert('메시지 전송에 실패했습니다.');
        } finally {
            setIsLoading(false);  
        } 
    }
    return {
        messages, 
        text, 
        isLoading,
        onFAQSubmit,
        onScenario,
        setText
    }
}